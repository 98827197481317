.heading-alt-5 {
  /* height: 500px;
  width: 100%;
  background-color: black;
  position: relative; */
  /* background: linear-gradient(
      45deg,
      rgba(44, 44, 44, 0.25),
      rgba(44, 44, 44, 0.25)
    ),
    url(../Assets/bg-about.jpg); */
  height: 100vh !important;
  width: 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 10px 10px 80px 10px rgba(44, 44, 44, 0.45);
}
.heading-alt-5 h2 {
  font-weight: normal;
  width: 100%;
  position: absolute;
  font-size: 180px;
  text-align: center;
  font-family: var(--sans-serif) !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.heading-alt-5 h2:nth-child(1) {
  color: transparent;

  -webkit-text-stroke: 2px white;
}
.heading-alt-5 h2:nth-child(2) {
  color: white;

  animation: animate 4s ease-in-out infinite;
}
@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      15% 44%,
      32% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }
  50% {
    clip-path: polygon(
      0% 60%,
      16% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}
@media screen and (max-width:850px){
  .heading-alt-5 h2 {
     font-size: 100px;
   }
}
  @media screen and (max-width:450px){
  .heading-alt-5 {
    height: 400px;
     }
  .heading-alt-5 h2{
    font-size: 80px;
  }
}
@keyframes tipsy {
    0% {
      transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }
  
    100% {
      transform: translateX(-50%) translateY(-50%) rotate(360deg);
    }
  }
  
  
  
  .heading-catSreen p {
    color: white;
    text-shadow: 0 20px 25px #2e2e31, 0 40px 60px #2e2e31;
    font-size: 80px;
    font-weight: bold;
    text-decoration: none;
    letter-spacing: -3px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
/*   
  .heading-catSreen p:before,
  .heading-catSreen p:after {
    content: '';
    padding: .9em .4em;
    position: absolute;
    left: 50%;
    width: 100%;
    top: 50%;
    display: block;
    border: 10px solid var(--primary);
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
    animation: 10s infinite alternate ease-in-out tipsy;
  }
  
  .heading-catSreen p:before {
    border-color: var(--primary) var(--primary) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    z-index: -1;
  }
  
  .heading-catSreen p:after {
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) var(--primary) var(--primary);
    box-shadow: 25px 25px 25px rgba(46, 46, 49, .8);
  } */
  .singleCatImg{
      height:700px;
      width: 100%;
  }
  .h_cardDiv{
      padding:50px 30px;
  }

  .category-quote-div {
    margin: 48px 0 0px 0;
    padding: 12px;
  }

  .category-quote-div .category-quote {
    color: var(--primary-black);
    font-weight: 500;
    font-size: 40px;
  }

  .category-line {
    font-size: 24px;
  }
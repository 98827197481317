.image-upload.center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 0 !important;
  }
  
  .image-upload__preview {
    width: 10rem;
    height: 10rem;
    border: 1px solid #ccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
    clip-path: circle(50% at 50% 50%);
  }
  
  .image-upload__preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .image-upload-button {
    color: #fff !important;
    background-color: #222 !important;
    border-color: #222 !important;
    border-radius: 50% !important;
    height: 50px !important;
    width: 50px !important;
  }
  
  .image-upload-button:hover {
    background-color: transparent !important;
    color: #222 !important;
  }
  
  .form-controll{
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
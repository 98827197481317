.iconholder-god {
    font-family: "";
    font-size: 18px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
  }
  
  .iconholder {
    background-color: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
  }
  
  .upar {
    height: 160px;
    width: 160px;
    color: white;
    z-index: 1;
    transition: 1.2s;
    background: black;
  }
  
  .andar {
    height: 220px;
    width: 220px;
    background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(https://images.unsplash.com/photo-1529044728228-9917df974897?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80);
    background-size: cover;
    color: white;
    box-shadow: 3px 3px 30px #aaa;
  }
  
  .upar:hover {
    transform: translateX(-90%);
    transition: 1.2s;
  }

  @media screen and (max-width: 990px) {
    .iconholder-god {
      font-family: "";
      font-size: 18px;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35vh;
    } 
  }
/* .header-main-main {
    margin-bottom: 132px !important;
}

.header-main {
    margin-bottom: 46px; 
} */

.header-main1 {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    /* box-shadow: 4px 0 15px rgb(151, 151, 151); */
    z-index: 99;
}


/*Scroll event styling*/
/* .header-main-exit {
    margin-bottom: 95px !important;
} */

/*Header 1st row*/
.header-main2 {
    /* height: 46px; */
    height: 10px;
    background-color: green !important;
    transition: all 0.4s ease;
    opacity: 0;
    pointer-events: all;
    overflow: hidden;
}

.header-main2-exit {
    height: 0 !important;
    pointer-events: none;
    opacity: 0;
}

/*Header 2nd row*/
.header-main3 {
    height: 80px;
    background-color: transparent;
}
.header-main3-exit{
    height: 75px;
    background-color: white;
}
.header-main31{
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin:auto;
}

.header-main32 {
    height: 86px;
    text-align: center;
    width: fit-content;
}
.header-main32 img {
    height: 72px;
    /* margin-top: 12px; */
}
.header-main3-exit img{
    height: 60px;
    margin-top: 6px;
}

.header-main33 {
    background-color: transparent;
    line-height: 80px;
}
.header-main3-exit .header-main33{
    line-height: 75px;
}
.header-main33 ul {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

.header-main33 a {
    color: rgb(112, 111, 111);
}

.header-main33 ul li {
    margin: 0 10px;
    font-size: 17px;
    color: white;
    font-family: 'Poppins', sans-serif;
}

.header-main3-exit ul li {
    color: #222 ;
}

.header-main33 ul li:hover {
    color: var(--primary) !important;
    font-family: 'Poppins', sans-serif;
}

.cat-dropdown{
    background-color: white;
    position: absolute;
    margin-left: -23px;
    color: #222222;
    padding: 15px 20px !important;
    text-align: left !important;
    margin-top: 0px !important;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-30px);
    transition: all 0.4s ease;
}
.cat-dropdown-child{
    padding: -0px 5px !important;
    margin: 0 5px ;
    border-bottom: 1.5px solid rgb(214, 212, 212) ;
    line-height: 45px !important;
}
.dropdown-iinit:hover .cat-dropdown{
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
}

.deconone:hover {
    text-decoration: none !important;
}



@media screen and (max-width: 990px) {
    .header-main31 {
        width: 95%;
    }

    .header-main21 {
        width: 95%;
    }
}

@media screen and (max-width: 840px) {
    .header-main31 {
        width: 100%;
    }

    .header-main32 {
        margin-left: 30px;
    }

    .header-main33 {
        position: fixed;
        width: 275px !important;
        height: 100% !important;
        flex-direction: column !important;
        align-items: start !important;
        justify-content: start !important;
        text-align: left !important;
        padding-top: 60px !important;
        transform: translateX(-275px);
        transition: all 0.4s ease-out;
        padding-left: 20px !important;
        z-index: 1000;
        line-height: 75px;
        background-color: #222222 !important;
        margin-top: -10px;
    }
    .header-main3-exit .header-main33{
        margin-top: 0px;
    }

    .header-main33 ul {
        display: unset;
    }
    .header-main3-exit ul li {
        color: white ;
    }
    .header-main33.open {
        transform: translateX(0px);
    }

    .hamburger {
        z-index: 100;
        height: 40px;
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 20px;
        right: 18px !important;
        cursor: pointer;
        position: absolute;
        padding: 2px 8px 0 0;
        /* border: 1px solid rgb(133, 131, 131); */
    }

    .hamburger div {
        background-color: white !important;
        margin: 6px 0 0 8px;
        width: 30px;
        height: 4px;
    }
    .cat-dropdown{
        margin-left: 50px;
        position: absolute;
    }
    .dropdown-iinit:hover .cat-dropdown{
        transform: translateY(0);
        opacity: 1;
        pointer-events: all;
    }
    .hamburger-exit div {
        background-color: black !important;
        margin: 6px 0 0 8px;
        width: 30px;
        height: 4px;
    }   
}

.hamburger-exit {
    top: 17px !important;
}
.header-dropdown-link {
    text-decoration: none !important;
    transition: 0.25s ease;
}

.header-dropdown-link:hover {
    color: var(--primary) !important;
}

@media screen and (max-width: 550px) {
    .header-main21 {
        display: flex;
        text-align: left !important;
        flex-direction: column-reverse;
    }

    .header-main22 {
        color: white;
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: 0.6px;
    }

    .header-main32 {
        margin-left: 23px;
    }
}



.quick-inq {

    background-color: var(--primary);

    position: fixed;
    top: 43%;
    width: 40px;
    height: 155px;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    transition: all 0.8s ease !important;
    z-index: 90;
}

.quick-inq1 {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    line-height: 40px;
    color: #222222;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    margin-top: 6px;
    font-weight: bold;
}

.quick-inq2 {

    background-color: var(--primary);

    width: 300px;
    height: 450px;
    position: fixed;
    top: 19.2%;
    transform: translateX(-310px);
    transition: all 0.8s ease;
    border-radius: 0 5px 5px 0;
    /* box-shadow: 3px 6px 10px rgb(212, 212, 212); */
    z-index: 100;
}

.open-quick {
    transform: translateX(0px) !important;
}

.open-quick1 {
    transform: translate(300px, -30px) !important;
}

.form-mainss {
    width: 85%;
    margin: auto;
}

.form-mainss1 {
    position: absolute;
    width: 85%;
    top: 48%;
    transform: translateY(-50%);
}

.form-mainss1 input,
.nobb-form,
.nobbs-form {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-bottom: 1px solid black !important;
    border-radius: 0 !important;
    padding-left: 38px !important;
}

.form-mainss1 input::placeholder,
.nobb-form::placeholder {
    color: black !important;
    font-size: 15px;
}

.icone-form {
    position: absolute;
    font-size: 22px;
    margin-top: 8px !important;
    margin-left: -125px;
}

.form-mainss button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 95.3%;
    height: 40px;
    padding: 0 40px;
    border: none;
    background-color: black;
    color: white;
    transition: all 0.4s ease;
}

.form-mainss button:hover {
    background-color: rgb(214, 212, 212);
    color: black;
}
.erryu{
    color: white;
    font-size: 12px;
    text-align: left;
}
.team-section {
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 40px;
  padding-right: 40px;
  text-align: left;
}

.team-cards-aks {
  padding-top: 80px;
  padding-bottom: 35px;
  padding: 20px 260px 5px;
}

.container-team {
  display: flex;
  margin: 0 auto;
  width: fit-content;
  box-shadow: 5px 5px 15px rgb(187, 185, 185);
  border-radius: 12px;
}

.container-team .post {
  width: 250px;
  height: 300px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  position: relative;
  border-radius: 12px;
  border: 0.15px solid var(--primary-black)
}

.container-team .post:hover .header_post {
  margin-top: -20px;
}

.container-team .post:hover .body_post {
  height: 45%;
}

.container-team .post:hover img {
  transform: translatey(-10px) translatex(-5px) scale(1.05);
}

.container-team .post .header_post {
  width: 100%;
  height: 60%;
  background: #ddd;
  position: absolute;
  top: 0;
  -webkit-transition: cubic-bezier(0.68, -0.55, 0.27, 1.55) 320ms;
  -moz-transition: cubic-bezier(0.68, -0.55, 0.27, 1.55) 320ms;
  -ms-transition: cubic-bezier(0.68, -0.55, 0.27, 1.55) 320ms;
  -o-transition: cubic-bezier(0.68, -0.55, 0.27, 1.55) 320ms;
  transition: cubic-bezier(0.68, -0.55, 0.27, 1.55) 320ms;
}

.container-team .post .header_post img {
  width: 100% !important;
  height: 55vh !important;
  object-fit: fill;
  transition: ease-in-out 600ms;
}

.container-team .post .body_post {
  width: 100%;
  height: 0%;
  background: #fff;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: cubic-bezier(0.68, -0.55, 0.27, 1.55) 320ms;
  -moz-transition: cubic-bezier(0.68, -0.55, 0.27, 1.55) 320ms;
  -ms-transition: cubic-bezier(0.68, -0.55, 0.27, 1.55) 320ms;
  -o-transition: cubic-bezier(0.68, -0.55, 0.27, 1.55) 320ms;
  transition: cubic-bezier(0.68, -0.55, 0.27, 1.55) 320ms;
  cursor: pointer;
}

.container-team .post .body_post .post_content {
  width: 80%;
  height: 40%;
  background: #fff;
  position: relative;
}

.container-team .post .body_post .post_content h1 {
  font-size: 20px;
  font-weight: bold;
}

.container-team .post .body_post .post_content p {
  font-size: 14px;
  font-weight: normal;
}

@keyframes top {
  0% {
    opacity: 0;
    bottom: -80px;
  }

  100% {
    opacity: 1;
    bottom: 0px;
  }
}

@keyframes icon {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: scale(1.3) rotate(-2deg);
  }

  100% {
    opacity: 1;
    bottom: 0px;
  }
}

@media screen and (max-width: 768px) {
  .container-team {
    margin-bottom: 25px;
  }

  .container-team .post .header_post img {
    width: 100% !important;
    height: 56vh !important;
    object-fit: fill;
    transition: ease-in-out 600ms;
  }
}

.category-card-img {
  height: 120px;
  width: 120px;
  margin-top: 12px;
  transition: 0.5s ease-in-out;
}

.category-card {
  width: fit-content;
  height: fit-content;
  text-align: center;
  border: none !important;
  transition: 0.5s ease-in-out;
}

.category-card:hover {
  transform: scale(1.1);
}

.category-card-title {
  font-size: 18px;
  margin-top: 16px;
  margin-bottom: 12px;
  color: var(--primary);
  font-weight: 500;
  text-decoration: none !important;
}

.team-cards-aks a {
  text-decoration: none !important;
}

.category-card-body {
  padding: 12px !important;
}

.category-card-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width : 1260px) {

  .team-cards-aks {
    padding-top: 80px;
    padding-bottom: 35px;
    padding: 20px 200px 5px;
  }
}

@media screen and (max-width : 1060px) {

  .team-cards-aks {
    padding-top: 80px;
    padding-bottom: 35px;
    padding: 20px 160px 5px;
  }
}

@media screen and (max-width : 580px) {

  .team-cards-aks {
    padding-top: 80px;
    padding-bottom: 35px;
    padding: 20px 80px 5px;
  }
}

@media screen and (max-width : 400px) {

  .team-cards-aks {
    padding-top: 80px;
    padding-bottom: 35px;
    padding: 20px 40px 5px;
  }
}

@media screen and (max-width : 300px) {

  .team-cards-aks {
    padding-top: 80px;
    padding-bottom: 35px;
    padding: 20px 20px 5px;
  }
}
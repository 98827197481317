@keyframes tipsy {
  0% {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }

  100% {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
}
.heading-alt-3 {
  /* height: 500px;
  width: 100%;
  background-color: black;
  position: relative; */
  background: linear-gradient(45deg, rgba(44, 44, 44, 0.25), rgba(44, 44, 44, 0.25)), url(../Assets/bg-contactus.jpg);
  height: 100vh !important;
  width: 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 10px 10px 80px 10px rgba(44, 44, 44, 0.45);
}
.heading-alt-3 h2 {
  font-weight: normal;
  width: 100%;
  position: absolute;
  font-size: 180px;
  text-align: center;
  font-family: var(--sans-serif) !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.heading-alt-3 h2:nth-child(1) {
  color: transparent;

  -webkit-text-stroke: 2px white;
}
.heading-alt-3 h2:nth-child(2) {
  color: white;

  animation: animate 4s ease-in-out infinite;
}
@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      15% 44%,
      32% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }
  50% {
    clip-path: polygon(
      0% 60%,
      16% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}
@media screen and (max-width:850px){
  .heading-alt-3 h2 {
     font-size: 100px;
   }
}
  @media screen and (max-width:450px){
  .heading-alt-3 {
    height: 400px;
     }
  .heading-alt-3 h2{
    font-size: 80px;
  }
}

.heading-contact {

  background: linear-gradient(45deg, rgba(44, 44, 44, 0.25), rgba(44, 44, 44, 0.25)), url(../Assets/bg-contactus.jpg);
  height: 100vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 10px 10px 80px 10px rgba(44, 44, 44, 0.45);
}

.heading-contact p {
  color: white;
  text-shadow: 0 20px 25px #2e2e31, 0 40px 60px #2e2e31;
  font-size: 80px;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: -3px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
/* 
.heading-contact p:before,
.heading-contact p:after {
  content: '';
  padding: .9em .4em;
  position: absolute;
  left: 50%;
  width: 100%;
  top: 50%;
  display: block;
  border: 10px solid var(--primary);
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
  animation: 10s infinite alternate ease-in-out tipsy;
}

.heading-contact p:before {
  border-color: var(--primary) var(--primary) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  z-index: -1;
}

.heading-contact p:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) var(--primary) var(--primary);
  box-shadow: 25px 25px 25px rgba(46, 46, 49, .8);
} */

/*********************************** Form CSS **************************************************/

.contact-us-container {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-color: #fff !important;
  padding: 2rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-us-form {
  width: 100%;
  max-width: 820px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contact-form {
  background-color: var(--primary);
  position: relative;
}

.circle {
  border-radius: 50%;
  background: linear-gradient(135deg, transparent 20%, var(--secondary));
  position: absolute;
}

.circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

.contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: var(--primary);
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

.contact-us-main-form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.contact-form-title {
  color: #fff;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.contact-form-input-container {
  position: relative;
  margin: 1rem 0;
}

.contact-form-label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fafafa;
  font-size: 0.9rem;
  pointer-events: none;
  z-index: 1000;
  transition: 0.5s;

}

.contact-input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 12px;
  transition: 0.3s;
}

textarea.contact-input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  resize: none;
  overflow-y: auto;
}

.contact-textarea label {
  top: 1rem;
  transform: translateY(0);
}

.contact-button {
  padding: 0.6rem 1.3rem;
  background-color: #fff;
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  color: var(--primary);
  line-height: 1;
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
}

.contact-button:hover {
  background-color: transparent;
  color: #fff;
}

.contact-form-input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}

.contact-form-input-container span:before,
.contact-form-input-container span:after {
  content: "";
  position: absolute;
  width: 10%;
  opacity: 0;
  transition: 0.3s;
  height: 5px;
  top: 50%;
  background-color: var(--primary);
  transform: translateY(-50%);
}

.contact-form-input-container span::before {
  left: 50%;
}

.contact-form-input-container span::after {
  right: 50%;
}

.contact-form-input-container.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.contact-form-input-container.focus span::before,
.contact-form-input-container.focus span::after {
  width: 50%;
  opacity: 1;
}

/**************************************************** CONTACT INFO SIDE ****************************************************/

.contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-info-title {
  color: var(--primary);
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.contact-info-text {
  color: var(--primary-black);
  margin: 1.5rem 0 2rem 0;
}

.information {
  display: flex;
  color: var(--primary-black-text);
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
}

.contact-info-icon {
  width: 28px;
  margin-right: 0.7rem;
}

.social-media {
  padding: 2rem 0 0 0;
}

.social-media p {
  margin-bottom: 0;
  color: var(--primary-black);
}

.social-icons {
  display: flex;
  margin-top: 0.5rem;
}

.social-icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: linear-gradient(45deg, var(--primary), var(--secondary));
  text-align: center;
  line-height: 35px;
  color: #fff;
  margin-right: 0.5rem;
  transition: 0.3s;
}

.social-icons a:hover {
  transform: scale(1.05);
}

.contact-info {
  text-align: left;
}

.contact-info:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 100px;
  border: 22px solid var(--primary);
  border-radius: 50%;
  bottom: -50px;
  right: 50px;
  opacity: 0.3;
}

.big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(to bottom, var(--primary), var(--secondary));
  bottom: 50%;
  right: 50%;
  transform: translate(-40%, 38%);
}

.big-circle:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #fafafa;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(181%, 11%);
  opacity: 0.2;
}

@media (max-width: 850px) {
  .contact-us-form {
    grid-template-columns: 1fr;
  }

  .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .square {
    transform: translate(140%, 43%);
    height: 350px;
  }

  .big-circle {
    bottom: 75%;
    transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .contact-info-text {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    padding: 1.5rem 0 0 0;
  }
}

@media (max-width: 480px) {
  .contact-us-container {
    padding: 1.5rem;
  }

  .contact-info:before {
    display: none;
  }

  .square,
  .big-circle {
    display: none;
  }

  form,
  .contact-info {
    padding: 1.7rem 1.6rem;
  }

  .contact-info-text,
  .information,
  .social-media p {
    font-size: 0.8rem;
  }

  .contact-form-title {
    font-size: 1.15rem;
  }

  .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .contact-info-icon {
    width: 23px;
  }

  .contact-input {
    padding: 0.45rem 1.2rem;
  }

  .contact-button {
    padding: 0.45rem 1.2rem;
  }
}
.contact-us-link {
  text-decoration: none !important;
  color: var(--primary-black);
}

.contact-us-link:hover {
  color: var(--primary-black) !important;
}
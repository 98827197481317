.section-log-ax {
  width: 100%;
  height: 160vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.form-container-ax {
  width: 80%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 0.5rem;
  perspective: 100rem;
}

.forgot {
  text-align: left !important;
  margin-left: 14px;
}

.google-btn {
  background-color: transparent !important;
  border: none !important;
}

.form-img-ax {
  width: 40rem;
  background-color: transparent;
  padding: 5rem;
  opacity: 0.8;
  border-radius: 0.5rem;
  -webkit-filter: drop-shadow(25px 25px 25px #222);
  filter: drop-shadow(25px 25px 25px #222);
  transform: rotateY(20deg);
  transition: all 0.5s;
}

.form-img-ax:hover {
  transform: translateY(-2rem) rotateY(10deg);
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}

.contact-form-ax {
  width: 45rem;
  background-color: rgba(128, 231, 195, 0.3);
  display: flex;
  flex-direction: column;
  padding: 3rem;
  border-radius: 0.5rem;
  /* box-shadow: 3rem 3rem 4rem #80e7c3; */
  transform: rotateY(-20deg);
  transition: all 0.5s;
}

.contact-form-ax:hover {
  transform: translateY(-2rem) rotateY(-10deg);
  /* box-shadow: 5rem 5rem 7rem #80e7c3; */
}

.contact-form-ax input {
  margin: 1rem;
  padding: 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 0rem;
  box-shadow: inset 0.01rem 0.01rem 0.02rem #222,
    inset -0.01rem -0.01rem 0.02rem #222;
}

.contact-form-ax input[type="submit"] {
  background-color: black;
  color: #fff;
  font-weight: bolder;
  cursor: pointer;
  transition: background-color 0.5s;
}

.contact-form-ax input[type="submit"]:hover {
  background-color: black;
}

.contact-form-ax h3 {
  text-align: center;
}

.contact-form-ax p {
  text-align: center;
}

.form-img-alt {
  height: 40px;
  width: 40px;
}

.google-signin {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.line-bro {
  color: black;
  height: 6px;
  width: 300px;
}

.image-upload {
  padding-top: 20px !important;
}

@media (max-width: 1400px) {
  .form-container-ax {
    height: 60%;
  }

  .form-img-ax {
    display: none;
  }

  .contact-form-ax {
    transform: translateY(0);
  }

  .contact-form-ax:hover {
    transform: translateY(-1rem);
  }
}

@media (max-width: 550px) {
  .section-4 {
    height: auto;
  }

  .form-container-ax {
    background-color: transparent;
    box-shadow: none;
  }

  .contact-form-ax {
    box-shadow: 1rem 1rem 2rem #aaa;
  }

  .contact-form-ax:hover {
    box-shadow: 2rem 2rem 3rem #aaa;
  }
}

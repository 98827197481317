/* @keyframes tipsy {
  0% {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }

  100% {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
} */
.heading-alt-1 {
  /* height: 500px;
  width: 100%;
  background-color: black;
  position: relative; */
  background: linear-gradient(45deg, rgba(44, 44, 44, 0.25), rgba(44, 44, 44, 0.25)), url("../Assets/headingg.jpg");
  height: 100vh !important;
  width: 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 10px 10px 80px 10px rgba(44, 44, 44, 0.45);
}
.heading-alt-1 h2 {
  font-weight: normal;
  width: 100%;
  position: absolute;
  font-size: 180px;
  text-align: center;
  font-family: var(--sans-serif) !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.heading-alt-1 h2:nth-child(1) {
  color: transparent;

  -webkit-text-stroke: 2px white;
}
.heading-alt-1 h2:nth-child(2) {
  color: white;

  animation: animate 4s ease-in-out infinite;
}
@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      15% 44%,
      32% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }
  50% {
    clip-path: polygon(
      0% 60%,
      16% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}
@media screen and (max-width:960px){
  .heading-alt-1 h2 {
     font-size: 140px !important;
   }
}
@media screen and (max-width:850px){
  .heading-alt-1 h2 {
     font-size: 100px;
   }
}
@media screen and (max-width:750px){
  .heading-alt-1 h2 {
     font-size: 80px !important;
   }
}
  @media screen and (max-width:450px){
  .heading-alt-1 {
    height: 400px;
     }
  .heading-alt-1 h2{
    font-size: 60px !important;
  }
}
.heading-categories {

  background: linear-gradient(45deg, rgba(44, 44, 44, 0.25), rgba(44, 44, 44, 0.25)), url("../Assets/headingg.jpg");
  height: 100vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 10px 10px 80px 10px rgba(44, 44, 44, 0.45);
}

.heading-categories p {
  color: white;
  text-shadow: 0 20px 25px #2e2e31, 0 40px 60px #2e2e31;
  font-size: 65px;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: -3px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/* .heading-categories p:before,
.heading-categories p:after {
  content: '';
  padding: .9em .4em;
  position: absolute;
  left: 50%;
  width: 100%;
  top: 50%;
  display: block;
  border: 10px solid var(--primary);
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
  animation: 10s infinite alternate ease-in-out tipsy;
}

.heading-categories p:before {
  border-color: var(--primary) var(--primary) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  z-index: -1;
}

.heading-categories p:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) var(--primary) var(--primary);
  box-shadow: 25px 25px 25px rgba(46, 46, 49, .8);
} */


.h_categoryPage {
  background-image: url("../Assets/category_page.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  width: 100%;
  padding-top: 150px;
  padding-left: 70px;
  padding-bottom: 70px;
  padding-right: 50px;
}

.categoryCard {
  background-color: rgb(255, 255, 255, .10);
  box-shadow: 0 8px 32px 0 rgba(39, 48, 161, 0.37);
  backdrop-filter: blur(8.0px);
  -webkit-backdrop-filter: blur(8.0px);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  height: 380px;
  width: 340px;

  padding: 40px 20px;

  transition: all 0.4s ease-out;
  overflow: hidden;
  display: relative;
  justify-content: space-evenly;
  border-radius: 10px;
}

.catt {
  text-align: center;
  color: white;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
}

.catt h1 {
  font-size: 80px;
}

.categoryImg {

  height: 300px;
  width: 300px;
  border-radius: 150px;
  display: block;
  transition: all 0.4s ease-out;
  max-width: 100%;
  transform-origin: top;

}

.categoryCard:hover img {
  transform: scale(0.7);
}

.categoryDiv {
  margin-left: 45px;
}

.categoryCard h1 {

  transition: all 0.4s ease-out;
  transform: translateY(150%);
  font-family: 'Poppins', sans-serif;
  font-weight: bolder !important;

}

.categoryCard p {
  transition: all 0.4s ease-out;
  transform: translateY(150%);
  font-family: 'Poppins', sans-serif;
  font-weight: bolder !important;
}

.categoryCard:hover h1 {
  transition: all 0.4s ease-out;

  transform: translateY(-170%);

}

.categoryCard:hover p {
  transition: all 0.4s ease-out;

  transform: translateY(-190%);

}
.sidebar-menu-item {
    transition: 0.3s ease-in-out;
}

.sidebar-menu-item:hover {
    background-color: var(--secondary);
}

.pro-item-content {
    font-weight: 500;
}
.admin-dashboard-sidebar {
    height: 100vh !important;
    width: 100%;
}

.admin-dashboard-sidebar-header-div {
    padding: 8px;
    margin-top: 16px;
}

.admin-dashboard-sidebar-heading {
    color: #fff;
    font-weight: bold;
}

.admin-dashboard-sidebar-footer {
    margin-top: auto;
    margin-bottom: 16px;
    padding: 8px;
    text-align: center;
}
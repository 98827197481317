.admin-dashboard-content {
    padding-left: 80px !important;
    padding-top: 20px;
    height: 100vh !important;
    overflow-y: scroll;
    padding-bottom: 60px;
}

.admin-dashboard-title {
    text-align: center;
    font-size: 50px;
    margin-top: 20px;
}

.admin-dashboard-cards-row {
    margin-top: 100px;
}

.admin-dashboard-card,
.admin-dashboard-card-body {
    background-color: var(--primary) !important;
    width: 80%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--white);
    border-radius: 8px !important;
}

.admin-dashboard-card {
    transition: 0.25s ease-in-out;
    box-shadow: 12px 12px 24px 12px rgba(0, 0, 0, 0.1)
}

.admin-dashboard-card:hover {
    transform: scale(1.125);
}

.admin-dashboard-link {
    text-decoration: none !important;
}

.admin-dashboard-form {
    text-align: left;
    margin: 40px 40px 0 0;
}

.admin-dashboard-form-button {
    margin: 0 8px;
    background-color: var(--primary) !important;
    border: 2px solid var(--primary) !important;
    transition: 0.1s;
}

.admin-dashboard-form-button:hover {
    background-color: transparent !important;
    color: var(--primary) !important;
    border: 2px solid var(--primary) !important;
}

.admin-dashboard-form-group {
    margin-top: 24px;
}

.form-admin-title {
    margin-top: 40px;
}

.admin-add-package-button {
    background-color: var(--primary) !important;
    border: 1px solid var(--primary) !important;
    width: 100%;
    transition: 0.25s ease-in-out;
}

.admin-add-package-button:hover {
    background-color: transparent !important;
    color: var(--primary) !important;
}

.label-text {
    font-size: 14px;
    margin-bottom: 6px;
    color: rgba(0, 0, 0, 0.65);
}

/* BOOKING CSS */


.admin-booking-card {
    padding: 4px;
    border-radius: 10px !important;
    text-align: justify !important;
}

.admin-booking-card img {
    height: 310px;
    max-width: 100% !important;
    object-fit: cover;
    object-position: left;
    border-radius: 8px;
}

.admin-booking-card p {
    margin-bottom: 8px;
    font-size: 14px;
    text-align: justify !important;
}

.pdf-invisible {
    display: none !important;
}
.cp-wrapper {
  height: 100vh;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url('https://images.unsplash.com/photo-1506905925346-21bda4d32df4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80');
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

.review {
  color: #323031;
  font-size: 1.4rem;
  padding: 3rem;
  position: relative;
  background-color: #fff;
  box-shadow: 25px 25px 60px 15px rgba(255, 255, 255, 0.5);
  overflow: hidden;
  flex: 0 0 50%;
  z-index: 1;
}

@media screen and (max-width: 56.25em) {
  .review {
    padding: 2rem;
  }
}

@media screen and (max-width: 37.5em) {
  .review {
    padding: 1rem;
    font-size: 1.2rem;
  }
}

.review::before {
  content: "“";
  position: absolute;
  left: -0.9rem;
  top: -2.5rem;
  font-size: 20rem;
  color: #D9DCD6;
  line-height: 1;
  z-index: 1;
  font-family: sans-serif;
}

.review__text {
  margin-bottom: 2rem;
  z-index: 2;
  position: relative;
}

@media screen and (max-width: 56.25em) {
  .review__text {
    margin: 0;
    padding: 1rem;
  }
}

.review__person {
  display: flex;
  align-items: center;
}

.review__photo {
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 50%;
  margin-right: 1rem;
}

@media screen and (max-width: 37.5em) {
  .review__photo {
    margin-right: 0.5rem;
  }
}

.review__info {
  display: flex;
  flex-flow: row wrap;
  margin-right: auto;
}

.review__info--name {
  flex: 0 0 80%;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: -0.5rem;
}

@media screen and (max-width: 37.5em) {
  .review__info--name {
    font-size: 0.9rem;
  }
}

.review__info--date {
  font-size: 0.9rem;
}

@media screen and (max-width: 37.5em) {
  .review__info--date {
    font-size: 0.7rem;
  }
}

.review__rating {
  color: var(--color-grey-dark);
  font-size: 2.2rem;
  font-weight: 300;
}